<template>
  <div>
    <chat-window
      :height="'500px'"
      :current-user-id="currentUserId"
      :rooms="rooms"
      :rooms-loaded="roomsLoaded"
      :loading-rooms="loadingRooms"
      :messages="messages"
      :messages-loaded="messagesLoaded"
      @fetch-messages="onFetchMessages"
      @send-message="envia"
      @open-file="openFile"
      :load-first-room="false"
      :message-actions="messageActions"
      :text-messages="{
        ROOMS_EMPTY: 'Sem atendimento',
        ROOM_EMPTY: 'Selecione um atendimento',
        NEW_MESSAGES: ' NOVA MENSAGEM ',
        MESSAGE_DELETED: 'Ce message a été supprimé',
        MESSAGES_EMPTY: 'Sem mensagens',
        CONVERSATION_STARTED: 'Essa conversa teve início em :',
        TYPE_MESSAGE: 'Fala ai...',
        SEARCH: 'Pesquisar...',
        IS_ONLINE: '',
        LAST_SEEN: 'dernière connexion ',
        IS_TYPING: 'est en train de taper...',
        CANCEL_SELECT_MESSAGE: 'Annuler Sélection',
      }"
    >
      <template #add-icon> . </template>
      <template #room-header="{ room }">
        <a :href="room.avatar" target="_blank">
          <b-avatar
            :src="room.avatar"
            class="ml-2"
            size="3.5rem"
            @click="editCall()"
          />
        </a>
        <div>
          <h4 class="ml-2 mt-2 hover" @click="editCall(room)">
            {{ room.roomName }}
          </h4>
          <p v-if="room.imobiliaria" class="ml-2 hover" @click="editCall(room)">
            {{ room.imobiliaria.name }}
          </p>
        </div>
        <div v-for="tag in room.tags" :key="tag">
          <b-badge class="ml-1 hover" variant="primary" @click="editCall(room)"
            >{{ tag }}
          </b-badge>
        </div>

        <div class="right mr-1">
          <b-button variant="primary" v-b-modal.changeAttendant>
            <feather-icon icon="PhoneForwardedIcon" />
          </b-button>
          <b-button variant="danger" class="ml-1" v-b-modal.closeCall>
            <feather-icon icon="PhoneMissedIcon" />
          </b-button>
        </div>
        <!-------------------------------------------------------------->
        <!---------------  - MODALS CLOSE CALL-------------------------->
        <b-modal
          id="closeCall"
          title="Fechar Atendimento"
          @ok="closeCall(room)"
          ok-title="Salvar"
          cancel-title="Cancelar"
        >
          <b-col cols="12">
            <h4 class="m-2">Realmente deseja fechar esse chamado?</h4>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Assunto do chamado" class="mt-1">
              <v-select
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="option"
                class="select-size-sm"
              >
                <span slot="no-options"
                  >Selecione "outros" e de um novo titulo nos comentários</span
                >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label for="textarea-default">Resumo do atendimento</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Conte-nos como foi o atendimento"
              rows="3"
            />
          </b-col>
        </b-modal>
        <!----------------------------------------------------------------------->
        <!---------------  - MODALS CHANGE ATTENDANT - -------------------------->
        <b-modal
          id="changeAttendant"
          title="Transferir Atendimento"
          @ok="setNewAttedant(room)"
          cancel-title="cancelar"
          ok-title="Tranferir"
        >
          <b-col cols="12">
            <b-form-group label="Selecione o novo atendente" class="mt-1">
              <v-select
                v-model="attendantSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="attendant"
                class="select-size-md"
                :selectable="(option) => option.name != user.user.name"
              >
                <span slot="no-options"
                  >Selecione "outros" e de um novo titulo nos comentários</span
                >
              </v-select>
            </b-form-group>
          </b-col>
        </b-modal>
        <!----------------------------------------------------------------------->
        <!----------------------  - MODALS EDIT CALL - -------------------------->
        <b-modal
          id="editCall"
          title="Transferir Atendimento"
          @ok="saveCall()"
          cancel-title="cancelar"
          ok-title="Salvar"
        >
          <b-col cols="12">
            <b-form-group label="Nome" label-for="largeInput">
              <b-form-input
                id="largeInput"
                size="lg"
                placeholder="Large Input"
                v-model="editedDataRoom.roomName"
              />
            </b-form-group>
            <b-form-group label="E-mail" label-for="largeInput">
              <b-form-input
                size="lg"
                placeholder=""
                v-model="editedDataRoom.email"
              />
            </b-form-group>
          </b-col>
          <!-- IMOBILIÁRIAS -->
          <b-col cols="12">
            <b-form-group label="Selecione uma imobiliária" class="mt-1">
              <v-select
                v-model="editedDataRoom.imobiliaria"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="imobiliarias"
                class="select-size-md"
              >
                <span slot="no-options"
                  >Imobiliária não encontrada, você esta no empreendimento
                  correto?</span
                >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label for="tags-basic">Etiquetas</label>
            <b-form-tags
              v-model="editedDataRoom.tags"
              input-id="tags-basic"
              class="mb-2"
            />
          </b-col>
        </b-modal>
      </template>
    </chat-window>
  </div>
</template>

<script>
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import axios from "axios";
import { baseApiUrl } from "@/global";
import { mapState } from "vuex";
import vSelect from "vue-select";

export default {
  name: "Atendimento",
  components: {
    ChatWindow,
    vSelect,
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      currentUserId: 1287,
      roomsLoaded: false,
      messagesLoaded: false,
      loadingRooms: true,
      messages: [],
      rooms: [],
      selected: "",
      history: "",
      attendantSelected: undefined,
      option: [],
      editCallModal: false,
      editedDataRoom: {},
      imobiliarias: [],
      messageActions: [
        {
          name: "replyMessage",
          title: "Responder",
        },
      ],
    };
  },
  methods: {
    reset() {
      this.loadingRooms = true;
      this.messagesLoaded = false;
      this.rooms = [];
      this.selected = "";
      this.history = "";
      this.attendantSelected = undefined;
      this.getAtendimentos();
    },
    editCall(room) {
      this.editedDataRoom = { ...room };
      this.$bvModal.show("editCall");
      this.getImobs();
    },
    async saveCall() {
      if (this.editedDataRoom.imobiliaria.name === "Nenhuma")
        this.editedDataRoom.imobiliaria = {};

      await axios
        .put(`${baseApiUrl}/wp/calls`, this.editedDataRoom)
        .then((res) => {
          if (res.data.success) {
            this.$bvToast.toast("Contato salvo com sucesso!", {
              title: "Sucesso !",
              variant: "primary",
              solid: true,
            });
          }
        });
    },
    envia(msg) {
      let mensagem = { ...msg };
      //SETA RESPONSÁVEL PELA MSG
      mensagem.content = `*${this.user.user.name}:*\n${msg.content}`;
      //Checa se há arquivo
      if (msg.files) {
        for (let i = 0; msg.files.length > i; i++) {
          setTimeout(() => {
            //TRANSFORMA EM BASE64
            let base64data;
            var reader = new FileReader();
            reader.readAsDataURL(msg.files[i].blob);
            reader.onloadend = async function () {
              mensagem.type = msg.files[i].type;
              base64data = reader.result;
              mensagem.base64data = base64data
                .replace("data:", "")
                .replace(/^.+,/, "");
              //ENVIA
              axios.post(`${baseApiUrl}/wp/send`, mensagem);
              //ATUALIZA LASTMSG ROOM
            };
          }, 2000);
        }
      }
      axios.post(`${baseApiUrl}/wp/send`, mensagem);
    }, // **************

    async getAtendimentos() {
      await axios
        .post(`${baseApiUrl}/wp/calls`, {
          id: this.user.user.id,
        })
        .then((res) => {
          this.rooms = res.data;
        });
      this.roomsLoaded = true;
      this.loadingRooms = false;
    }, // **************

    async onFetchMessages(room) {
      this.messages = [];
      this.messagesLoaded = false;
      setTimeout(async () => {
        await axios
          .post(`${baseApiUrl}/wp/messages`, { call: room.room })
          .then((res) => {
            this.messages = res.data;
          });
        this.messagesLoaded = true;
        this.loadingRooms = false;
        this.roomsLoaded = true;
      });
      await axios.put(`${baseApiUrl}/wp/messages`, {
        wppSender: room.room.roomId,
      });
    }, // **************

    async getAttendant() {
      this.attendant = [{ name: "Fila", _id: "" }];
      await axios.get(`${baseApiUrl}/users`).then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          this.attendant.push(res.data.data[i]);
        }
      });
    }, // **************

    async setNewAttedant(room) {
      //NEGA SE NÃO ESTIVER NADA SELECIONADO
      if (!this.attendantSelected) {
        this.$bvToast.toast("Selecione um novo atendente!", {
          title: "Erro !",
          variant: "danger",
          solid: true,
        });
        return;
      }
      // SETA DATA PARA ATUALIZAR
      let call = { ...room };
      call.attendant = this.attendantSelected._id;
      await axios.put(`${baseApiUrl}/wp/calls`, call).then((res) => {
        if (res.data.success) {
          this.$bvToast.toast("Atendimento transferido com sucesso", {
            title: "Sucesso !",
            variant: "primary",
            solid: true,
          });
        }
      });
      // MENSAGEM DO SERVIDOR
      call.content = `${this.user.user.name} transferiu para ${this.attendantSelected.name}.`;
      await axios.post(`${baseApiUrl}/wp/system`, call);
      this.reset();
      this.getAtendimentos();
    }, // **************

    openFile({ file }) {
      window.open(file.file.url, "_blank");
    }, // **************

    closeCall(room) {
      //CONFERE SE O MOTIVO FOI SELECIONADO
      if (!this.selected.length) {
        this.$bvToast.toast("Selecione ao menos um motivo ", {
          title: "Erro !",
          variant: "danger",
          solid: true,
        });
        return;
      }

      // SALVA OS DADOS DO ATENDIMENTO
      let atendimento = {
        attendant: room.attendant,
        imobiliarias: room.imobiliaria,
        roomId: room.roomId,
        tags: room.tags,
        callId: room._id,
        timestamp: room.timestamp,
        feedback: {
          motivo: this.selected,
          history: this.history,
        },
      };
      axios
        .post(`${baseApiUrl}/wp/register`, {
          db: 'visao_main',
          atendimento,
        })
        .then((res) => {});

      // ATUALIZA ROOM
      room.attendant = "";
      room.unreadCount = 0;
      room.open = false;
      axios.put(`${baseApiUrl}/wp/calls`, room);
      // MENSAGEM DO SERVIDOR
      let msgSystem = { ...room };
      msgSystem.content = `${this.user.user.name} finalizou esse atendimento.`;
      axios.post(`${baseApiUrl}/wp/system`, msgSystem);
      //RESETA
      this.reset();
    }, // **************

    edit(room) {
      console.log("edit", room);
    }, // *************************

    async getImobs() {
      await axios
        .post(`${baseApiUrl}/cadastros`, {
          db: this.user.user.produto.db,
          returnData: "name ",
        })
        .then((res) => {
          this.imobiliarias = res.data.data;
        });
      this.imobiliarias.unshift({ name: "Nenhuma" });
    },
    getOption() {
      axios.get(`${baseApiUrl}/calloptions`).then((res) => {
        this.option = res.data[0].options;
      });
    },
  },
  mounted() {
    this.getAtendimentos();
    this.getAttendant();
    this.getOption();
  },
  sockets: {
    atualizaCall() {
      this.getAtendimentos();
      this.roomsLoaded = true;
    },
    newMessage(newMessage) {
      if (!this.messages[0]) return;
      if (this.messages[0].wppSender === newMessage.wppSender) {
        this.messages.push(newMessage);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.right {
  position: absolute;
  right: 5px;
}

.hover:hover {
  cursor: pointer;
}
</style>