<template>
  <div>
    <b-card no-body>
      <b-tabs card v-model="tab">
        <b-tab title="Chamados" active>
          <Chamados :atender="atender" />
        </b-tab>
        <b-tab title="Atendimentos">
          <Atendimentos />
        </b-tab>
        <b-tab title="Conversas">
          <Conversas/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Chamados from "./components/Chamados.vue";
import Atendimentos from "./components/Atendimentos.vue";
import Conversas from "./components/Conversas.vue"
import axios from "axios";
import { baseApiUrl } from "@/global";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["user"]),
  },
  components: {
    Chamados,
    Atendimentos,
    Conversas
  },
  data() {
    return {
      tab: 0,
    };
  },
  methods: {
    async atender(wppSender) {
      this.tab = 1;
      console.log(wppSender);
      wppSender.attendant = this.user.user.id;
      await axios.put(`${baseApiUrl}/wp/calls`, wppSender);
      let msgSystem = { ...wppSender };
      msgSystem.content = `Atendimento inciado por ${this.user.user.name}`;
      axios.post(`${baseApiUrl}/wp/system`, msgSystem);
    },
  },
};
</script>

<style>
</style>