<template>
  <div>
    <b-table :items="items" :fields="fields" striped responsive>
      <template #cell(actions)="row">
        <b-button
          class="vs-label"
          @click="getMessageCalls(row.item.roomId, row.toggleDetails())"
          :variant="row.detailsShowing ? 'danger' : 'primary'"
          >{{ row.detailsShowing ? "Fechar" : "Detalhe" }}</b-button
        >
      </template>

      <template #row-details="room">
        <b-card>
          <b-row class="mb-2">
            <b-col md="9" v-for="message in messages" :key="message._id">
              <strong>Mensagem : </strong> {{ message.content }}
            </b-col>
            <b-col md="3">
              <b-button size="sm" variant="primary" @click="atender(room.item)">
                <b-icon icon="chat-dots" />
                Atender
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <template #cell(roomId)="data">
        {{ data.item.roomId.substr(0, 13) }}
      </template>

      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>
      <template #cell(imobiliaria)="data">
        <div v-if="data.item.imobiliaria">
          {{ data.item.imobiliaria.name }}
        </div>
      </template>

      <template #cell(funcao)="data">
        <div v-for="tag in data.item.tags" :key="tag">
          <b-badge variant="light-primary">
            {{ tag }}
          </b-badge>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
export default {
  props: {
    atender: {
      type: Function,
    },
  },
  data() {
    return {
      fields: [
        { key: "roomId", label: "ID" },
        ,
        { key: "avatar", label: "Avatar" },
        { key: "imobiliaria", label: "Imobiliaria" },
        { key: "roomName", label: "Nome" },
        { key: "funcao", label: "Função" },
        { key: "actions", label: "Ações" },
      ],
      items: [],
      messages: [],
    };
  },
  methods: {
    getChamados() {
      axios.get(`${baseApiUrl}/wp/calls`).then((res) => {
        this.items = res.data;
      });
    },
    getMessageCalls(id) {
      axios.post(`${baseApiUrl}/wp/calls/message`, { id }).then((res) => {
        this.messages = res.data;
      });
    },
  },
  sockets: {
    atualizaCall() {
      this.getChamados();
    },
  },
  mounted() {
    this.getChamados();
  },
};
</script>
 